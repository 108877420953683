<template>
  <div class="bg-gray-800">
    <div class="flex flex-wrap">
      <div class="w-full">
        <BlockGroup
          :label="'Illumination & Background'"
          :closed="false"
        >
          <!-- <InputWrapper
            type="info"
            :label="'Adjust the brightness and background of the scene'"
            :extra="'Scenes may include their own lighting.'"
          /> -->

          <InputWrapper
            type="slider"
            :label="'Lighting Exposure'"
            :value="getSetting('exposure')"
            :min="exposureMin"
            :max="exposureMax"
            :step="exposureStep"
            @change="onChange('exposure', $event)"
          />

          <InputWrapper
            type="select"
            :label="'Skybox'"
            :value="getSetting('background.skybox')"
            :values="skyboxOptions"
            @change="onChange('background.skybox', $event)"
          />

          <InputWrapper
            type="slider"
            :label="'Env Map Intensity'"
            :value="getSetting('background.envmap.intensity')"
            :min="0"
            :max="1"
            :step="0.01"
            @change="onChange('background.envmap.intensity', $event)"
          />
          <!-- <InputWrapper
            type="color"
            v-bind:disabled="true"
            v-bind:label="'Background Color'"
            v-bind:extra="'Viewer background flat color.'"
            v-bind:value="getSetting('background.color')"
            v-on:change="onChange('background.color', $event)"/> -->
        </BlockGroup>

        <BlockGroup :label="'Global Lights'">
          <InputWrapper
            type="toggle"
            :label="'Global Ambient Light'"
            :value="getSetting('light.ambient.visible')"
            @change="onChange('light.ambient.visible', $event)"
          />
          <div v-if="getSetting('light.ambient.visible')">
            <InputWrapper
              type="slider"
              :label="'Strength'"
              :value="getSetting('light.ambient.intensity')"
              :short="true"
              :min="ambientMin"
              :max="ambientMax"
              :step="ambientStep"
              :nested="true"
              @change="onChange('light.ambient.intensity', $event)"
            />
            <InputWrapper
              type="color"
              :label="'Color'"
              :value="getSetting('light.ambient.color')"
              :short="true"
              :nested="true"
              @change="onChange('light.ambient.color', $event)"
            />
          </div>

          <InputWrapper
            type="toggle"
            :label="'Global Hemisphere Light'"
            :value="getSetting('light.hemi.visible')"
            @change="onChange('light.hemi.visible', $event)"
          />
          <div v-if="getSetting('light.hemi.visible')">
            <InputWrapper
              type="slider"
              :label="'Strength'"
              :value="getSetting('light.hemi.intensity')"
              :short="true"
              :min="hemiMin"
              :max="hemiMax"
              :step="hemiStep"
              :nested="true"
              @change="onChange('light.hemi.intensity', $event)"
            />
            <InputWrapper
              type="color"
              :label="'Color'"
              :value="getSetting('light.hemi.color')"
              :short="true"
              :nested="true"
              @change="onChange('light.hemi.color', $event)"
            />
            <InputWrapper
              type="color"
              :label="'Ground Color'"
              :value="getSetting('light.groundcolor.color')"
              :short="true"
              :nested="true"
              @change="onChange('light.groundcolor.color', $event)"
            />
            <InputWrapper
              type="position"
              :label="'Position'"
              :values="hemiLightPositions"
              :short="true"
              :nested="true"
              @change="onChangeHemiPosition"
            />
          </div>
          <!--

          <InputWrapper
            type="button"
            v-bind:simple="true"
            v-bind:value="'View & Modify'"
            v-bind:label="'Global Ambient Light'"
            v-on:change="viewGlobalAmbient"/>

          <InputWrapper
            type="button"
            v-bind:simple="true"
            v-bind:value="'View & Modify'"
            v-bind:label="'Global Hemisphere Light'"
            v-on:change="viewGlobalHemi"/> -->
        </BlockGroup>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../GuiSettingMixin'

export default {
  mixins: [mixin],

  data () {
    return {
      exposureMin: 0,
      exposureMax: 2,
      exposureStep: 0.1,

      ambientMin: 0,
      ambientMax: 10,
      ambientStep: 0.1,

      hemiMin: 0,
      hemiMax: 10,
      hemiStep: 0.1
    }
  },

  computed: {
    hemiLightPositions () {
      return {
        x: this.getSetting('light.hemi.position.x'),
        y: this.getSetting('light.hemi.position.y'),
        z: this.getSetting('light.hemi.position.z')
      }
    },

    skyboxOptions () {
      return {
        'default': 'Default',
        'blue': 'Blue',
        'gray': 'Light Gray',
        'darkgray': 'Dark Gray',
        'black': 'Solid Black'
      }
    }
  },

  methods: {

    onChangeHemiPosition (values) {
      if (values.x !== undefined) this.onChange('light.hemi.position.x', values.x)
      if (values.y !== undefined) this.onChange('light.hemi.position.y', values.y)
      if (values.z !== undefined) this.onChange('light.hemi.position.z', values.z)
    }

    // viewGlobalAmbient () {
    //   this.$bus.$emit('model:scenegraph:viewnode', { id: 'global_ambient_light', type: 'mesh' })
    // },

    // viewGlobalHemi () {
    //   this.$bus.$emit('model:scenegraph:viewnode', { id: 'global_hemisphere_light', type: 'mesh' })
    // }
  }
}

</script>
